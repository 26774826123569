<template>
  <div>
    <div class="row align-items-center border-bottom pb-3">
      <label class="col-xl-6 col-form-label font-weight-bolder">放行附件</label>
      <div class="col-xl-6 text-right" v-if="audit.applicationsType !== '3'">
        <button class="btn btn-sm btn-outline-primary">电脑上传</button>
        <a href="javascript:;" class="fileUp">
          <input id="file" type="file" v-on:change="checkFile" multiple/>
        </a>
      </div>
    </div>
    <div v-if="fileListPass && fileListPass.length > 0">
      <div v-for="(item, index) in fileListPass" :key="index">
        <div class="row p-3 align-items-center border-bottom">
          <div class="col-xl-8 text-primary">
            {{ item.name }}
          </div>
          <div class="col-xl-4 text-right">
            <button
                class="btn btn-sm btn-danger px-1 py-1"
                v-on:click="delFile(index)"
                v-if="audit.applicationsType !== '3'"
            >
              删除</button
            >&emsp;
            <button class="btn btn-sm btn-primary px-1 py-1" v-on:click="downloadFile(item.downloadUrl)">下载</button>&emsp;
            <button
                class="btn btn-sm btn-success px-1 py-1"
                v-on:click="showPreview(item)">
              预览
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-hidden="true" ref="previewIdT">
      <div class="modal-dialog modal-xl">
        <div class="modal-body">
          <div class="row">
            <div class="col-sm text-center">
              <div class="row">
                <div class="col-sm">
                  <img id="previewT" src="" class="w-100">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Modal} from "bootstrap";
export default {
  props: {
    status: String,
    editStatus: Boolean,
    fileListPass: Array,
    audit:Object,
  },
  data(){
    return{
      previewIdTwo: ""
    }
  },
  mounted() {
    this.previewIdTwo = new Modal(this.$refs.previewIdT);
  },
  methods: {
    downloadFile: function (url){
      if (!url){
        alert("只有上传以后的文件才能下载");
        return;
      }
      window.open(url,'_blank');
    },
    delFile: function(index) {
      if (!this.status){
        alert("文件删除链接错误！");
        return;
      }
      if (this.fileListPass[index] && this.fileListPass[index].downloadUrl) {
        // 删除后台关联及存储
        const url = this.status + "/delFileTwo";
        this.$axios
            .fetchPost(url, this.fileListPass[index])
            .then(data => {
              if (data.data) {
                if (data.data === "success") {
                  alert("删除成功");
                  this.fileListPass.splice(index, 1);
                  this.$emit("setFileListPass", this.fileListPass);
                } else {
                  alert("系统错误");
                }
              }
            })
            .catch(err => {
              console.log(err);
            });
      } else {
        this.fileListPass.splice(index, 1);
        this.$emit("setFileListPass", this.fileListPass);
      }
    },
    checkFile: function(file) {
      if (file.target.files && file.target.files.length > 0) {
        for (let x = 0; x < file.target.files.length; x++) {
          const f = file.target.files[x];
          const type = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ];
          if (f && type.indexOf(f.type) >= 0) {
            for (const i in this.fileListPass) {
              if (this.fileListPass[i].name === f.name) {
                alert("文件“" + f.name + "”已经存在！");
                return;
              }
            }
            this.fileListPass.push(f);
            this.$emit("setFileListPass", this.fileListPass);
          } else {
            alert(
                "只能上传jpg/jpeg/png/pdf/doc/docx/xls/xlsx/ppt/pptx后缀名的文件"
            );
          }
        }
      }
    },
    showPreview: function(item) {
      const file = item;
      const type = ["image/jpg", "image/jpeg", "image/png"];
      const typePdf = ["application/pdf"];
      if (file && type.indexOf(file.type) >= 0) {
        if (item.downloadUrl) {
          this.previewIdTwo.show();
          document.getElementById("previewT").src = item.downloadUrl;
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file)
          const _this = this;
          reader.onload = function() {
            _this.previewIdTwo.show();
            document.getElementById("previewT").src = reader.result;
          };
        }
      } else if (file && typePdf.indexOf(file.type) >= 0) {
        if (item.downloadUrl) {
          const win = window.open();
          win.document.write(
              '<body style="margin:0px;"><iframe src="' +
              item.downloadUrl +
              '" scrolling="no" width="100%" height="100%" frameborder="0" ></iframe></body>'
          );
          win.document.close();
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function() {
            const win = window.open();
            win.document.write(
                '<body style="margin:0px;"><iframe src="' +
                reader.result +
                '" scrolling="no" width="100%" height="100%" frameborder="0" ></iframe></body>'
            );
            win.document.close();
          };
        }
      } else {
        if (item.downloadUrl) {
          if (file.type) {
            const win = window.open();
            win.document.write(
                '<body style="margin:0px;"><iframe src="https://view.officeapps.live.com/op/view.aspx?src=' +
                encodeURIComponent(item.downloadUrl) +
                '" scrolling="no" width="100%" height="100%" frameborder="0" ></iframe></body>'
            );
            win.document.close();
          } else {
            const strs = item.name.split(".");
            if (
                strs[1] === "jpg" ||
                strs[1] === "JPG" ||
                strs[1] === "png" ||
                strs[1] === "PNG" ||
                strs[1] === "jpeg" ||
                strs[1] === "JPEG" ||
                strs[1] === "bmp" ||
                strs[1] === "BMP" ||
                strs[1] === "gif" ||
                strs[1] === "GIF"
            ) {
              this.previewIdTwo.show();
              document.getElementById("previewT").src = item.downloadUrl;
            } else if (
                strs[1] === "docx" ||
                strs[1] === "DOCX" ||
                strs[1] === "doc" ||
                strs[1] === "DOC" ||
                strs[1] === "xls" ||
                strs[1] === "XLS" ||
                strs[1] === "xlsx" ||
                strs[1] === "XLSX"
            ) {
              const win = window.open();
              win.document.write(
                  '<body style="margin:0px;"><iframe src="https://view.officeapps.live.com/op/view.aspx?src=' +
                  encodeURIComponent(item.downloadUrl) +
                  '" scrolling="no" width="100%" height="100%" frameborder="0" ></iframe></body>'
              );
              win.document.close();
            } else if (strs[1] === "pdf" || strs[1] === "PDF") {
              const win = window.open();
              win.document.write(
                  '<body style="margin:0px;"><iframe src="' +
                  item.downloadUrl +
                  '" scrolling="no" width="100%" height="100%" frameborder="0" ></iframe></body>'
              );
              win.document.close();
            }
          }
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function() {
            const win = window.open();
            win.document.write(
                '<body style="margin:0px;"><iframe src="' +
                reader.result +
                '" scrolling="no" width="100%" height="100%" frameborder="0" ></iframe></body>'
            );
            win.document.close();
          };
        }
      }
    }
  }
};
</script>
