<template>
  <div>
    <div v-if="audit && audit.recordList">
      <div class="row pt-5 pb-5 border-bottom">
        <div class="col-xl-12 font-weight-bolder">
          审批记录<span>[{{ audit.recordList.length }}条]</span>
        </div>
      </div>
      <div class="row align-items-center pt-3 pb-3 border-bottom">
        <div class="col-xl-1">
          <span
            class="symbol symbol-35 symbol-light-success"
            v-if="audit.employee"
          >
            <img
              v-if="audit.employee.downloadUrl"
              alt="Pic"
              :src="audit.employee.downloadUrl"
            />
            <span
              v-else-if="audit.employee.empChar"
              class="symbol-label font-size-h5 font-weight-bold"
            >
              {{ audit.employee.empChar }}
            </span>
          </span>
        </div>
        <div class="col-xl-8">
          <div>
            [<span>提交</span>]&nbsp;<span v-if="audit.auditType !== '22026'">{{ audit.recordList[0].createdByName }}</span>
            <div>{{ audit.recordList[0].createdDate }}</div>
          </div>
        </div>
      </div>
      <div v-for="item in audit.recordList" :key="item.recordId">
        <div
          class="row align-items-center pt-3 pb-3 border-bottom"
          v-if="item.approveUsers"
        >
          <div class="col-xl-1">
            <span
              class="symbol symbol-35 symbol-light-success"
              v-if="item.employee"
            >
              <img
                v-if="item.employee.downloadUrl"
                alt="Pic"
                :src="item.employee.downloadUrl"
              />
              <span
                v-else-if="item.employee.empChar"
                class="symbol-label font-size-h5 font-weight-bold"
              >
                {{ item.employee.empChar }}
              </span>
            </span>
          </div>
          <div class="col-xl-9">
            <div v-if="item.status">
              <span v-if="item.status == 'assent'" class="text-success"
                >[同意]</span
              >
              <span v-if="item.status == 'reject'" class="text-danger"
                >[驳回]</span
              >&nbsp; {{ item.handelPositionIdName }}-{{ item.lastUpdByName }}
            </div>
            <div v-else>待{{ item.handelPositionIdName }}审批</div>
            <div class="row" v-if="item.content">
              <div class="col-xl-12">{{ item.content }}</div>
            </div>
            <div class="row align-items-center">
              <div class="col-xl-9">
                <div v-if="!item.status">
                  <span v-if="item.approveUsersName"
                    >审批人：{{ item.approveUsersName }}</span
                  >
                  <span v-else
                    >该职位审批人不存在，请联系相关人员配置该职位的审批人</span
                  >
                </div>
                {{ item.lastUpdDate }}
              </div>
            </div>
          </div>
          <div class="col-xl-2 text-right">
            <button
              class="btn btn-sm btn-outline-primary px-1 py-1"
              v-on:click="reply(item)"
            >
              回复</button
            >&nbsp;
            <button
              class="btn btn-sm btn-outline-primary px-1 py-1"
              v-on:click="close(item)"
            >
              <span v-if="!item.commentFlag">收起</span>
              <span v-else>展开</span>
            </button>
          </div>
        </div>
        <template v-for="commen in audit.commentList">
          <div
            v-if="
              !item.commentFlag &&
                commen.auditRecordId &&
                commen.auditRecordId == item.recordId
            "
            :key="commen.recordId"
          >
            <div
              class="row align-items-center p-2 alert-dark bg-bottom text-secondary border-bottom border-light"
            >
              <div class="col-xl-1">
                <span
                  class="symbol symbol-35 symbol-light-success"
                  v-if="commen.employee"
                >
                  <img
                    v-if="commen.employee.downloadUrl"
                    alt="Pic"
                    :src="commen.employee.downloadUrl"
                  />
                  <span
                    v-else-if="commen.employee.empChar"
                    class="symbol-label font-size-h5 font-weight-bold"
                  >
                    {{ commen.employee.empChar }}
                  </span>
                </span>
              </div>
              <div class="col-xl-9" style="word-break: break-word">
                <div>
                  <span>{{ commen.name }}&nbsp;</span>回复
                  <span v-if="commen.replyName">{{ commen.replyName }}</span>
                  <span v-else>
                    <span v-if="item.status">{{ item.lastUpdByName }}</span>
                    <span v-else>{{ item.approveUsersName }}</span></span
                  >
                  <span>：&nbsp;{{ commen.content }}</span>
                </div>
                <div class="row align-items-center">
                  <div class="col-xl-12">{{ commen.createdDate }}</div>
                </div>
              </div>
              <div class="col-xl-2 text-right">
                <button
                  class="btn btn-sm btn-outline-secondary px-1 py-1"
                  v-on:click="replyCom(item, commen)"
                >
                  回复
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      class="modal fade"
      ref="comment_audit"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row pt-3">
              <span class="pl-3">评论内容</span>
              <div class="col-xl-12 pt-1">
                <textarea
                  class="form-control"
                  v-model="comments.content"
                  rows="6"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              取消
            </button>
            <button type="button" class="btn btn-primary" v-on:click="commit">
              发布
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
export default {
  props: {
    audit: Object,
    user: Object
  },
  data() {
    return {
      comments: {},
      commentT: ""
    };
  },
  mounted() {
    this.commentT = new Modal(this.$refs.comment_audit);
    this.comments = {};
  },
  methods: {
    close: function(item) {
      if (item.commentFlag) {
        this.$set(item, "commentFlag", false);
      } else {
        this.$set(item, "commentFlag", true);
      }
    },
    reply: function(item) {
      if (item && item.recordId) {
        this.comments.auditRecordId = item.recordId;
      }
      this.commentT.show();
    },
    replyCom: function(item, commen) {
      if (item && item.recordId) {
        this.comments.auditRecordId = item.recordId;
      }
      if (commen && commen.recordId) {
        this.comments.commentId = commen.recordId;
      }
      this.commentT.show();
    },
    commit: function() {
      if (this.comments && this.comments.content) {
        if (this.audit && this.audit.recordId) {
          if (this.user.employeeId) {
            this.comments.auditId = this.audit.recordId;
            this.comments.createdBy = {};
            this.comments.createdBy.recordId = this.user.employeeId;
            // 弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            this.$axios
              .fetchPost("kybAudit/addAuditComment", this.comments)
              .then(data => {
                if (data && data.data) {
                  this.audit.commentList = data.data;
                  this.comments = {};
                }
                this.commentT.hide();
                // 弹窗结束
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
              })
              .catch(err => {
                console.log(err);
              });
          } else {
            alert("用户未登陆，请登录");
          }
        } else {
          alert("当前页面失效，请退出重进");
        }
      } else {
        alert("请填写评论内容");
      }
    }
  }
};
</script>
